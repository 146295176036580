import React from 'react';
import './App.css';
import MiddleSection from './components/middle';
import Discord from './assets/discord.svg';
import Mail from './assets/mail.svg';
import X from './assets/x.svg';
import './styles/theme/variables.css'; // Importing variables.css
import './styles/global.css'; 

function App() {

  const handleResumeClick = () => {
    //G Drive resume link
    window.open('https://drive.google.com/drive/folders/1IcT6L1agBo0kH0hp38qwHzGAAnnZBLb0?usp=drive_link', '_blank', 'noopener noreferrer');
  };

  return (
    <div className="App">
      <div className="box-container first-div">
        <h1 className="main-heading">Designer who write code</h1>
        <p className="subtext">
        Based out of India. I live by the philosophy of experimenting around & decoding pieces. That’s how you truly learn.
        </p>
      </div>
      <div 
        className="primary-button"
        onClick={handleResumeClick}
        onKeyPress={(e) => e.key === 'Enter' && handleResumeClick()}
        role="button"
        tabIndex={0}
        aria-label="Open resume in new tab"
        style={{ cursor: 'pointer' }}
        >
        <span>Resume</span>
      </div>
      <MiddleSection />
      <div className="bottom">
        <h5 className="version">v1.0</h5>
        <div className="bottom-con">
          <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
            <img src={Discord} alt='discord logo' />
          </a>
          <a href="mailto:chinmayjoshi2021@iitkalumni.org">
            <img src={Mail} alt='mail' />
          </a>
          <a href="https://x.com/originalchinmay" target="_blank" rel="noopener noreferrer">
            <img src={X} alt='Twitter' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
