import React, { useState } from "react";
import axios from "axios";
import PasswordModal from "./passwordmodal";
import "./middle.css";
import PortfolioIcon from "../assets/gdrive.svg";
import FigmaIcon from "../assets/figma.svg";
import FramerIcon from "../assets/framer.svg";
import VSCode from "../assets/vscode.svg";
import ReactIcon from "../assets/react.svg";
import ClaudeAi from "../assets/claude ai.svg";
import ChatGPT from "../assets/chatgpt.svg";
import GitHub from "../assets/github.svg";
import Blender from "../assets/blender.svg";
import PenPot from "../assets/penpot.svg";

const stackIcons = [
    { src: FigmaIcon, alt: "Figma" },
    { src: FramerIcon, alt: "Framer" },
    { src: VSCode, alt: "VS Code" },
    { src: ReactIcon, alt: "React" },
    { src: ClaudeAi, alt: "Claude Ai" },
    { src: ChatGPT, alt: "OpenAI ChatGPT" },
    { src: GitHub, alt: "GitHub" },
    { src: Blender, alt: "Blender" },
    { src: PenPot, alt: "Penpot" },
];

const MiddleSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const PORTFOLIO_URL = 'https://drive.google.com/drive/folders/17okY0PzFPsG1vEls548J7OVw3f36DRkd?usp=drive_link';

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleSubmit = async () => {
        try {
            console.log('Attempting to authenticate with password:', password);
            // Log the full URL being called
            console.log('Calling API at:', '/api/auth');


            // Send the password to the /api/auth endpoint
            const response = await axios.post("/api/auth", { password });

            if (response.data.success) {
                window.location.href = PORTFOLIO_URL; // Redirect on success
            } else {
                setError("Incorrect password. Please try again."); // Show error on failure
            }
        } catch (err) {
            setError(err.response?.data?.message || "Authetication failed. Please try again");
        }
    };

    return(
        <div className="wrapper">
            <div className = "box-container">
                <h2>Portfolio</h2>
                <div
                onClick={toggleModal}
                className="portfolio-button">
                <img
                src={PortfolioIcon} // Replace with your portfolio icon link
                alt="Portfolio Icon"
                className="portfolio-icon"
                />
                <PasswordModal
                isOpen={isModalOpen} 
                password={password}
                setPassword={setPassword}
                error={error}
                handleSubmit={handleSubmit}
                onClose={toggleModal}
                />
                </div>
            </div>
            
            <div className="box-container">
                <h2>Tools Stack</h2>
                <div className="stacks-container">
                {stackIcons.map((stack, index) => (
                <div key={index} className="stack-icon">
                    <img src={stack.src} alt={`${stack.alt} icon`} />
                </div>
                ))}
                </div>
            </div>
        </div>
    );
}

export default MiddleSection;