import React from "react";
import "./passwordmodal.css";
import Lock from "../assets/lock.svg";

const PasswordModal = ({ 
                        isOpen, 
                        password, 
                        setPassword, 
                        error, 
                        handleSubmit, 
                        onClose }) => {
  if (!isOpen) return null

    return (
        <div>
            <div className="modal-overlay">
                {/* Modal Content */}
                <div 
                onClick={(e) => e.stopPropagation()}
                className="modal">
                    {/* Lock Icon and Title */}
                    <div className="modal-heading">
                        <div className="modal-title">
                            <img src={Lock} alt="lock icon" className="lock-icon"/>
                            <h4>Password Protected</h4>
                        </div>
                        <p className="subtext"> Enter password to view portfolio</p>
                    </div>

                    {/* Form */}
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                        }}>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter password"
                        />

                        {error && <p className="text-red-500">{error}</p>}

                        <div className="cta-container">
                            <button
                            type="button"
                            onClick={() => {
                                onClose(false); // Close the modal by calling parent-provided onClose function
                              }}
                            className="btn btn-secondary"
                            >
                            Cancel
                            </button>
                            <button
                            type="submit"
                            className='btn btn-primary'
                            >
                            Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PasswordModal;